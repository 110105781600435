<template>
  <ui-component-modal
    modalTitle="Manage mail addresses"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveMailAddresses"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-striped">
        <tbody>
          <tr
            v-for="(address, index) in mMailAddresses.filter(
              (m) => m.length > 0
            )"
            :key="index"
          >
            <td>{{ address }}</td>
            <td>
              <a
                @click="removeMailAddress(index)"
                class="has-icon has-text-danger"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">
              <input
                type="text"
                v-model="newEmailAddress"
                @keyup.enter="addEmailAddress"
                class="input"
              />
              <a @click="addEmailAddress" class="button is-success">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Add</span>
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
export default {
  components: {},

  props: {
    mailKey: {
      type: String,
      default: '',
    },

    mailadresses: {
      type: Array,
      default: function () {
        return []
      },
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mMailAddresses: JSON.parse(JSON.stringify(this.mailadresses)),
      newEmailAddress: '',
    }
  },

  computed: {},

  created() {},

  methods: {
    removeMailAddress(index) {
      this.mMailAddresses.splice(index, 1)
    },

    addEmailAddress() {
      this.mMailAddresses.push(this.newEmailAddress)
      this.newEmailAddress = ''
    },

    saveMailAddresses() {
      this.$emit('updateMailAddress', {
        mailKey: this.mailKey,
        addresses: this.mMailAddresses.join(','),
      })

      this.onClickCancel()
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: 102;
}
</style>
