import { render, staticRenderFns } from "./ModalMailAddresses.vue?vue&type=template&id=02ee8dc2&scoped=true"
import script from "./ModalMailAddresses.vue?vue&type=script&lang=js"
export * from "./ModalMailAddresses.vue?vue&type=script&lang=js"
import style0 from "./ModalMailAddresses.vue?vue&type=style&index=0&id=02ee8dc2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ee8dc2",
  null
  
)

export default component.exports